.searchBar {
  float: right;
  width: 20%;
}

.actionBar {
  margin: 15px 0;
}

.hoverTable {
  width: 100%;
}
