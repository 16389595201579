@import '../../../assets/styles/mixins.scss';

.card {
  padding: rem(15) rem(30) rem(20);
  position: relative;
  overflow: hidden;
}

.chart {
  height: 100%;
  width: 120px;
  position: absolute;
  top: 0;
  right: 0;
}

.amount {
  font-size: rem(36);
  color: $black;
  margin-bottom: rem(-10);
}

.title {
  color: $text;
  text-transform: uppercase;
  font-weight: bold;
}
