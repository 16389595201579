@import '../../../assets/styles/mixins.scss';

.container {
  background: linear-gradient(to right, #2170c1 0%, #2170c1 50%, white 50%, white 100%);
  @media (max-width: $sm-max-width) {
    background: linear-gradient(to right, white 0%, white 50%, white 50%, white 100%);
  }
}

.form {
  padding: 20%;
  height: 100vh;
}
