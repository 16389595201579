@import '../../../assets/styles/mixins.scss';

.buttonStyle {
  width: 100%;
}

.step {
  margin: 1.5% auto;
}

.button {
  width: 100%;
  margin-right: 50%;
}
