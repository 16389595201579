@import '../../../assets/styles/mixins.scss';

.header {
  margin-top: 0;
}

.formItem {
  margin-bottom: 0;
}

.unit {
  margin-inline-start: 10px;
}

.title {
  font-size: 16px;
  color: rgb(84, 84, 84);
  margin-bottom: 20px;
  display: block;
}

.layoutContent {
  padding: 0 50px;
  margin-top: 10px;
}

.buttonStyle {
  width: 100%;
}
